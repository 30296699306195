.container,
.App,
html {
  background: #1f1f1f;
  color: white;
}
a {
  color: white;
  text-decoration: none;
}
a:hover {
  transition: 0.25s;
  color: rgb(110, 109, 109);
}
a:not(:hover) {
  transition: 0.25s;
  color: white;
}
body {
  background: #1f1f1f;
}
.Home {
  margin-top: 35px;
}

.kartbuton {
  background-color: #1f1f1f;
}
.kartinfo {
  color: white;
}
.karttitle {
  color: white;
}
.card {
  background-color: #242222;
  border: solid rgb(49, 48, 48) 1px;
}

.card:last-child {
  margin-bottom: 30px;
}

.card-img-top {
  filter: grayscale(1);
}
.card-img-top:hover {
  transition: 0.5s;
  filter: grayscale(0);
}
.card-img-top:not(:hover) {
  transition: 0.5s;
  filter: grayscale(1);
}
.contactwrap {
  margin-top: 180px;
}
.contacttext {
  font-size: 18px;
}
.footer {
  background-color: rgb(27, 26, 26);
  padding: 10px;
}
.row {
  font-size: 18px;
}
.gitlink {
  text-decoration: underline;
}
